var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-2d",
                    defaultEnd: "6d",
                    label: "작업계획기간",
                    name: "plantDts",
                  },
                  model: {
                    value: _vm.searchParam.plantDts,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantDts", $$v)
                    },
                    expression: "searchParam.plantDts",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-dept", {
                  attrs: {
                    type: "search",
                    label: "정비요청 부서",
                    name: "reqDeptCd",
                    isFirstValue: false,
                  },
                  model: {
                    value: _vm.searchParam.reqDeptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "reqDeptCd", $$v)
                    },
                    expression: "searchParam.reqDeptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-dept", {
                  attrs: {
                    type: "search",
                    label: "정비오더 작업부서",
                    name: "workDeptCd",
                  },
                  model: {
                    value: _vm.searchParam.workDeptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "workDeptCd", $$v)
                    },
                    expression: "searchParam.workDeptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "WO_WORK_PLAN_STEP_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "woWorkPlanStepCd",
                    label: "진행상태",
                  },
                  model: {
                    value: _vm.searchParam.woWorkPlanStepCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "woWorkPlanStepCd", $$v)
                    },
                    expression: "searchParam.woWorkPlanStepCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "정비오더 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "woWorkPlanStepCd"
                    ? [
                        _c(
                          "q-chip",
                          {
                            staticClass: "full-size-chip",
                            class:
                              props.row.woWorkPlanStepCd == "WWPSC00000"
                                ? "blinking"
                                : "",
                            attrs: {
                              color: _vm.getColors(props.row.woWorkPlanStepCd),
                              outline: "",
                              square: "",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.row.woWorkPlanStepName) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  col.name === "equipmentTypeName"
                    ? [
                        _c(
                          "q-chip",
                          {
                            staticClass:
                              "q-ma-none customchipdept full-size-chip",
                            attrs: {
                              outline: "",
                              square: "",
                              color: "blue",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.row["equipmentTypeName"]) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  col.name === "emergencyFlagName"
                    ? [
                        props.row["emergencyFlagName"] == "긴급"
                          ? _c(
                              "q-chip",
                              {
                                staticClass: "q-ma-none customchipdept",
                                attrs: {
                                  outline: "",
                                  square: "",
                                  color: "red",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.row["emergencyFlagName"]) +
                                    " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(props.row["emergencyFlagName"])),
                            ]),
                        props.row.delayFlag == "Y"
                          ? _c("q-badge", {
                              staticClass: "blinking",
                              attrs: { color: "negative", label: "지연" },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "등록", icon: "add" },
                        on: { btnClicked: _vm.openPop },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }